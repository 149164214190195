import './RadarChart.css';
import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';

ChartJS.register( RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend );

const color={
  "Black": 'rgb(15, 32, 62)',
  "White": 'rgb(255, 255, 255)',
  "Main": 'rgb(28, 122, 172)',
  "Highlight": 'rgb(41, 181, 255)',
  "Gray": 'rgba(79, 91, 109)'
}

const options = {
  layout:{
    padding: {
        left: 0,
        right: 50,
        top: 0,
        bottom: 50
    }
  },
  plugins:{
    legend: {
      labels: {
          font: {
              size: 18,
          },
          color: color.Black
      },
      // display: false
    },
  },
  scales: {
    radar: {
      //グラフ背景
      // backgroundColor: 'snow',
      //グリッド線
      grid: {
        color: color.Black,
      },
      //軸線
      angleLines: {
        color: color.Black
      },
      //軸のラベル
      pointLabels: {
        color: color.Black,
        font: {
          size: 18
        } 
      },
      //目盛りの最小値・最大値
      beginAtZero: true,
      min: 0.6,
      max: 4,
      //目盛りのラベル
      ticks: {
        stepSize: 1,
        //ラベルの位置
        z: 0,
        padding: 3,
        //目盛りの文字
        color: color.Black,
        font: {
          size: 18
        },
        //目盛り背景
        backdropColor: color.White.replace(')', ', 0.85)'),
        // showLabelBackdrop: false,
      },
      // startAngle: 45,
    }
  }
}

function RadarChart({result}) {
  let data = {
    labels: [],
    datasets: [
      {
        label: '貴社',
        data: [],
        pointBackgroundColor: color.Highlight,
        pointHoverBackgroundColor: color.Highlight,
        backgroundColor: color.Highlight.replace(')', ', 0.5)'),
        borderColor: color.Highlight,
        borderWidth: 5,
      }
      // {
      //   label: 'DX取り組み企業',
      //   data: [],
      //   pointBackgroundColor: color.Gray,
      //   pointHoverBackgroundColor: color.Gray,
      //   backgroundColor: color.Gray.replace(')', ', 0.1)'),
      //   borderColor: color.Gray,
      //   borderWidth: 5,
      // }
    ]
  }

  data.labels = result.labels;
  data.datasets[0].data = result.result;
  // data.datasets[1].data = result.basedata;
  return (<div className="RadarChart-chart" >
    <Radar data={data} options={options} />
  </div>
  );
}

export default RadarChart;
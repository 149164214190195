import './Top.css';

import axios from "axios"
import ReactMarkdown from "react-markdown";
import {useState, useMemo} from "react"

import TermsOfService from '../component/TermsOfService';
import Fields from '../component/Fields';
import {Selector} from '../component/Selector';
import {TitleBar, SubTitleBar} from '../component/TitleBar';
import {Button, SimpleButton} from '../component/Button';
import ProgressBar from '../component/ProgressBar';
import Header from '../layout/Header';
import Footer from '../layout/Footer';

import Questions from '../contents/questions.json';
import QuestionPage from '../contents/question_page.json';
import Explain_Top from '../contents/explain_top.md';

import { allIncludes, personInputs } from '../utils/Calculator';
import { useParameterContext } from '../utils/States';


function Top() {
  let { state, dispatch } = useParameterContext();

  let [ page, setPage ] = useState(0);
  let [ tos, setTos ] = useState(false);

  const resetPerson = () => {
    dispatch( { "type": "delete_person", "payload": {} } )
  }

  const clickHandler = () => {
    let person = state.person;
    let header = {
      "Cache-Control": "no-cache",
    }
    // console.log("person", person)
    if(!(person.email === null || person.email === undefined || person.email === "") ){
      console.log("send")
      const isProduction = process.env.REACT_APP_ENV === "production"
      const POST_TARGET = isProduction ? process.env.REACT_APP_POST_TARGET_PROD : process.env.REACT_APP_POST_TARGET_DEV;
      // console.log("send", person.email)
      // console.log("isProduction:",isProduction)
      // console.log(POST_TARGET)
      axios.post(POST_TARGET, {
        
        "first_name": person.first_name || "",
        "last_name": person.last_name || "",
        "company": {
          "name": person.company_name || "",
          "category": person.company_category || "",
          "grade": person.company_grade || ""
        },
        "email": person.email || "",
        "phone": person.phone || "",
        "answers": state.answer
      },
      {headers: header}
      )
      // .then((res) => console.log(res))
      // .catch((err) => console.log(err))
    }
    resetPerson();
  }

  const [text, setText] = useState()
  fetch(Explain_Top).then(res => res.text()).then(txt => setText(txt));

  let content = [];
  let button = [];

  const page_size = QuestionPage.pages.length;

  switch(page){
    case 0:
      content = <section className="Top-section">
        <div className="Top-top-body">
          <ReactMarkdown children={text}/><br/>
        </div>
      </section>
      button = <section className="Top-button">
        <SimpleButton onClick={() => {setPage(page+1); window.scrollTo(0, 0);}}>次へ</SimpleButton>
      </section>
      break;
    case 1:
      content = <section className="Top-section">
        <SubTitleBar>利用規約</SubTitleBar>
        <TermsOfService />
        <label className="Top-tos-label" onChange={() => setTos(!tos)}>
          <input className="Top-tos-checkbox" type="checkbox" id="tos" name="terms_of_service" checked={tos} onChange={e => {}} required/>
          <div className="Top-tos-text">利用規約に同意する</div>
        </label>
      </section>
      button = <section className="Top-button">
        <SimpleButton disabled={!tos} onClick={() => {resetPerson(); setPage(page+1); window.scrollTo(0, 0);}}>次へ</SimpleButton>
      </section>
      break;
    case 2:
      const person_button_disable = !personInputs(state.person);
      content = <section className="Top-section">
        <SubTitleBar>お客様情報入力</SubTitleBar>
        <Fields />
      </section>
      button = <section className="Top-button">
        <SimpleButton disabled={person_button_disable} onClick={() => {setPage(page+1); window.scrollTo(0, 0);}}>次へ</SimpleButton>
      </section>
      break;
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
    case 11:
    case 12:
      const next_button_disable = !allIncludes(Object.keys(state.answer), QuestionPage.pages[page-3].tags)
      content = <section className="Top-section">
        <ProgressBar now={page-3+1} base={page_size}/>
        <SubTitleBar>{QuestionPage.pages[page-3].category}</SubTitleBar>
        {/* <SelectorAll /> */}
        <Selector qs={QuestionPage.pages[page-3].tags} />
      </section>
      button = <section className="Top-button">
        <SimpleButton onClick={() => {setPage(page-1); window.scrollTo(0, 0);}}>戻る</SimpleButton>
        {
        (page-3+1 === page_size)
        ? <Button to="/result" disabled={(Object.keys(state.answer).length !== Questions.contents.length)} onClick={clickHandler}>送信</Button>
        : <SimpleButton disabled={next_button_disable} onClick={() => {setPage(page+1); window.scrollTo(0, 0);}}>次へ</SimpleButton>
      }</section>
      break;
    default:
      setPage(0)
      console.log("setPage by error", page)
      break;
  }
  // console.log("page", page)

  return (
    <div className="Top">
      <Header />
      <TitleBar>DX Web診断</TitleBar>
      {content}
      {button}
      <Footer />
    </div>
  );
}

export default Top;
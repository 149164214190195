import './Result.css';

import ReactMarkdown from "react-markdown";
import { useState, useMemo } from "react";

import RadarChart from '../component/RadarChart';
import Offering from '../component/Offering';
import {GradeTable} from '../component/Table';
import {TitleBar, SubTitleBar, ContentTitleBar} from '../component/TitleBar';
import {Button, ExternalButton, ExternalButtonLarge} from '../component/Button';
// import MarkdownText from '../component/MarkdownText'
import Header from '../layout/Header';
import Footer from '../layout/Footer';

import { useParameterContext } from '../utils/States';
import { get4xScore, getDxScore, getOffering} from '../utils/Calculator';
import { getAverage, getMin, getVariance } from '../utils/Utils';

import Advice from '../contents/advice.json';
import {ReactComponent as Simple4x} from "../contents/simple_4x.svg"
import Explain_4X from '../contents/explain_4x.md';

function Result4XScoreColor(score){
  if(score < 2){
    return "Result-4x-score-contents-brown"
  }else if(score < 3){
    return "Result-4x-score-contents-cyan"
  }else{
    return "Result-4x-score-contents-blue"
  }
}

function Result() {
  let { state, dispatch } = useParameterContext();
  
  const clickHandler = () => {
    dispatch( { "type": "delete_person", "payload": {} } );
  }

  // console.log("answer", state.answer);

  const score_base =  useMemo(() => getDxScore(state.answer),[state.answer])
  const score_4x =  useMemo(() => get4xScore(state.answer),[state.answer])
  const data_base ={
    labels: ['経営', '組織', '人材', 'プロセス', 'カルチャー', '事業創出', 'DXリテラシー', 'ITアーキテクチャ', 'ITガバナンス', "データドリブン"],
    // result: [1.5, 1.8, 3.2, 2.0, 1.4, 2.2, 1.4, 2.9, 2.5, 3],
    result: score_base,
    basedata: [3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
  }
  const data_4X ={
    labels: ['CX', 'EX', 'OX', 'MX'],
    result: score_4x,
    basedata: [3, 3, 3, 3],
  }
  
  const average = useMemo(() => getAverage(data_4X.result), [data_4X.result]);
  const company_level = useMemo(() =>Advice.total.find(i => (i.point > average) ), [average])
  const dx_company = company_level.company;
  let score_text = company_level.body;

  const [min, idx] = useMemo(() => getMin(data_4X.result), [data_4X.result]);

  const offeringlist = useMemo(() => {
    const li = getOffering(state.answer)
    // delete duplicated offering
    const index = li.findIndex(i => i === Advice.suggest.offering[data_4X.labels[idx]])
    if(index !== -1){
      li.splice(index,1)
    }
    // add 4X offering
    li.unshift(Advice.suggest.offering[data_4X.labels[idx]])
    if(li.length >= 5){
      li.length = 4;
    }
    // console.log("offeringlist", li);
    return li
  } ,[state.answer, idx]);


  const [text, setText] = useState()
  fetch(Explain_4X).then(res => res.text()).then(txt => setText(txt));

  return (
    <div>
      <Header />
      <TitleBar>DX Web診断結果</TitleBar>
      <section className="Result-section">
        <ContentTitleBar >貴社のDXスコアは「{Math.floor((average * 10)) / 10}」「{dx_company}」になります</ContentTitleBar>
        <div className="Result-dxscore-body">{score_text}</div>
        <div className="Result-dxscore-result">
          <div>
            <ContentTitleBar >DX成熟度診断結果</ContentTitleBar>
            <div className="Result-dxscore-rader"><RadarChart result={data_base}/></div>
          </div>
          <div>
            <ContentTitleBar >DX成熟度のグレード</ContentTitleBar>
            <div className="Result-dxscore-table"><GradeTable average={average}/></div>
          </div>
        </div>
      </section>
      <TitleBar >企業変革の4軸に基づく分析</TitleBar>
      <section className="Result-section">
        <ContentTitleBar >Ridgelinez提唱フレームワーク：4Xについて</ContentTitleBar>
        <div className="Result-4x-explain">
          <div className="Result-4x-explain-body"><ReactMarkdown children={text}/></div>
          <div className="Result-4x-explain-img"><img className="Result-4x-explain-img-tag" src={Advice['4X'].images}/></div>
        </div>
        <div className="Result-4x-score">
          <ContentTitleBar >4Xに基づくDXスコア</ContentTitleBar>
          <div className="Result-4x-score-contents">
            <div className="Result-4x-score-contents-left">
              <div className="Result-4x-score-contents-left-top"><div className={Result4XScoreColor(data_4X.result[0])}>{data_4X.result[0].toFixed(1)}</div></div>
              <div className="Result-4x-score-contents-left-bottom"><div className={Result4XScoreColor(data_4X.result[3])}>{data_4X.result[3].toFixed(1)}</div></div>
            </div>
            <div className="Result-4x-score-contents-svg"><Simple4x/></div>
            <div className="Result-4x-score-contents-right">
              <div className="Result-4x-score-contents-right-top"><div className={Result4XScoreColor(data_4X.result[1])}>{data_4X.result[1].toFixed(1)}</div></div>
              <div className="Result-4x-score-contents-right-bottom"><div className={Result4XScoreColor(data_4X.result[2])}>{data_4X.result[2].toFixed(1)}</div></div>
            </div>
          </div>
        </div>
        <ContentTitleBar >貴社が強化するべきXは「{Advice.suggest.header[data_4X.labels[idx]]}」です</ContentTitleBar>
        <p className="Result-to-offering-body">{Advice.suggest.body[data_4X.labels[idx]]}</p>
      </section>
      <TitleBar>貴社におすすめするRidgelinezのオファリング</TitleBar>
      <section className="Result-section">
        <div className="Result-offering">
          <ContentTitleBar>オファリング一覧</ContentTitleBar>
          <Offering list={offeringlist}/>
        </div>
      </section>
      <TitleBar>お問い合わせ</TitleBar>
      <section className="Result-section">
        <div className="Result-advice">
          <ExternalButtonLarge to="https://www.ridgelinez.com/contact/form/service/">DXアセスメントについて問い合わせする</ExternalButtonLarge>
        </div>
      </section>
      <section className="Result-button">
        <ExternalButton to="https://www.ridgelinez.com/" newpage={false}>診断を終了する</ExternalButton>
        <Button to="/" onClick={clickHandler} main={false}>診断をやり直す</Button>
      </section>
      <Footer />
    </div>
  );
}

export default Result;
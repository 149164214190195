
export function getAverage(list){
  let sums = 0;
  for (const i of list) {
    sums += i;
  }
  return sums / list.length;
}
export function getMin(list){
  let min = list[0];
  let idx = 0;
  for(const i in list){
    if(min > list[i] ){
      min = list[i];
      idx = i;
    }
  }
  return [min, idx];
}
export function getVariance(list){
  let variance = 0;
  const average = getAverage(list)
  for(const i of list){
    variance += (i - average)**2;
  }
  variance = variance / list.length;
  return variance;
}

import './Offering.css';

import {ExternalButton} from '../component/Button';

import OfferingContents from "../contents/offering.json"
import { useState } from 'react';

function OfferingContainer({title, message, menu, link}) {
  const [expanded, setExpanded] = useState(false)

  const toggleAccordion = () => {
    setExpanded(!expanded ? true : false);
  };

  const items = [];
  // menu.length = 3;
  for(const m of menu){
    items.push(<li key={m}>{m}</li>)
  }

  return (<div className="OfferingContainer">
      <div className="OfferingContainer-title">{title}</div>
      <div className="OfferingContainer-body">
        <div className="OfferingContainer-message">{message}</div>
        {/* <h4 className="OfferingContainer-accordion" onClick={toggleAccordion}> 
        {expanded ? <div className="OfferingContainer-arrow-down"/> : <div className="OfferingContainer-arrow-right"/>}  　サービスメニュー
        </h4>
        {expanded && <ul>{items}</ul>} */}
        <div className="OfferingContainer-accordion">代表的なサービスメニュー</div>
        <ul>{items}</ul>
      </div>
      <div className="OfferingContainer-button">
        <ExternalButton to={link}>
          詳細はこちら
        </ExternalButton>
      </div>
    </div>
  );
}


function Offering({list}) {
  const items = [];
  for (const key of list){
    items.push(<OfferingContainer
      title={OfferingContents.contents[key].title}
      message={OfferingContents.contents[key].message}
      menu={OfferingContents.contents[key].main_menu}
      link={OfferingContents.contents[key].link}
      key={OfferingContents.contents[key].title}
      />)
  }

  return (
  <div className="Offering">
    <div className="Offering-container">
      {items}
    </div>
  </div>
  );
}

export default Offering;
import './NotFound.css';

import {Button} from "../component/Button"

function NotFound() {
  return (
    <div>
	    <h1>404</h1>
      <p>お探しのページは見つかりませんでした。</p>
      <div className="NotFound-link">
        <Button to="/">Top画面へ</Button>
      </div>
    </div>
  )
}

export default NotFound
import {x4XPoint, DxPoint, offerPoint, ansScore} from "./Parameters"

/////////////// scores ///////////////
function get4xSumScore(){
  const sumscore = [0,0,0,0];
  for(const i of Object.keys(x4XPoint)){
    for(const j in x4XPoint[i]){
      sumscore[j] += x4XPoint[i][j]
    }
  }
  // console.log("sumscore", sumscore)
  return sumscore
}
function getDxSumScore(){
  const sumscore = [0,0,0,0,0,0,0,0,0,0];
  for(const i of Object.keys(DxPoint)){
    for(const j in DxPoint[i]){
      sumscore[j] += DxPoint[i][j]
    }
  }
  // console.log("sumscore", sumscore)
  return sumscore
}
export function get4xScore(answer){
  const sumscore = get4xSumScore()

  const score = [0,0,0,0];

  for(const key of Object.keys(x4XPoint)){
    for(const j in x4XPoint[key]){
      score[j] += x4XPoint[key][j]*answer[key]
    }
  }

  for(const i in sumscore){
    score[i] = score[i] / sumscore[i]
  }
  // console.log("score", score)
  
  return score;
}
export function getDxScore(answer){
  const sumscore = getDxSumScore()

  const score = [0,0,0,0,0,0,0,0,0,0];

  for(const key of Object.keys(DxPoint)){
    for(const j in DxPoint[key]){
      score[j] += DxPoint[key][j]*answer[key]
    }
  }

  for(const i in sumscore){
    score[i] = score[i] / sumscore[i]
  }
  // console.log("score", score)
  
  return score;
}


/////////////// offering ///////////////
function getOfferingSumScore(){
  const sumofferings = {};
  
  // offerPoint
  for(const key of Object.keys(offerPoint)){
    for(const j of offerPoint[key]){
      sumofferings[j] = 0;
    }
  }
  for(const key of Object.keys(offerPoint)){
    for(const j of offerPoint[key]){
      sumofferings[j] += 1;
    }
  }
  return sumofferings
}
export function getOffering(answer){
  const offering_list = [];
  const offerings = {};

  const sumofferings = getOfferingSumScore()
  
  // offerPoint
  for(const j of Object.keys(sumofferings)){
    offerings[j] = 0;
  }

  for(const key of Object.keys(offerPoint)){
    for(const j of offerPoint[key]){
      offerings[j] += ansScore[answer[key]];
    }
  }

  for(const j of Object.keys(sumofferings)){
    offerings[j] = offerings[j] / sumofferings[j];
  }

  let sorted_offering = [];
  for (const key in offerings) {
      sorted_offering.push({"key": key, "value": offerings[key]});
  }

  sorted_offering.sort(function(a,b){return b.value-a.value})
  const offering = [];
  for (const i of sorted_offering){
    offering.push(i.key)
  }
  // if(offering.length >= 4){
  //   offering.length = 3;
  // }
  // if(offering.length === 0){
  //   offering.push("DX")
  // }

  // console.log("offering", offering)

  return offering;
}

// export function getOfferingFromScore(score){
//   let min = 4;
//   for(const i of score){
//     if(min > i ){
//       min = i
//     }
//   }
//   const idx = score.indexOf(min);
//   const offering = offerPointList[idx]

//   let average = 0;
//   for (const i of score) {
//     average += i;
//   }
//   average = average / score.length;

//   if(average >= 3){
//     offering.push("SX");
//   }

//   return offering;
// }

/////////////// person ///////////////

export function personInputs(person){
  const EMAIL_REGEX = /^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;

  let res = true;
  if((person.first_name === null    || person.first_name === undefined    || person.first_name === ""   )){res = false}
  if((person.last_name === null     || person.last_name === undefined     || person.last_name === ""    )){res = false}
  if((person.company_name === null  || person.company_name === undefined  || person.company_name === "" )){res = false}
  if((person.email === null         || person.email === undefined         || person.email === ""        )){res = false}
  if(!EMAIL_REGEX.test(person.email)){res = false}
  return res
}

/////////////// tags ///////////////

export function allIncludes(src, target){
  // src = ["Q1", "Q2", "Q3", "Q4", "Q5", "Q6", "Q7"]
  // target = ["Q6", "Q7", "Q8"]

  const include = src.filter(tag => {
    return ( target.includes(tag) );
  });

  return (target.length === include.length)
}
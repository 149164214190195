import './TitleBar.css';

export function TitleBar({children}) {
  return (<section className="TitleBar">
      <h1 className="TitleBar-title">{children}</h1>
    </section>
  );
}

export function SubTitleBar({children}) {
  return (<div className="TitleBar">
      <h2 className="TitleBar-subtitle">{children}</h2>
    </div>
  );
}

export function ContentTitleBar({children}) {
  return (<div className="TitleBar">
      <h3 className="TitleBar-subtitle">{children}</h3>
    </div>
  );
}

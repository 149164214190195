import './Button.css';

import { Link } from "react-router-dom";

export function ExternalButton({to, disabled=false, onClick=null, main=true, newpage=true, children}) {
  return (
    <a href={to} target={newpage? "_blank": "_self"}
       rel="noopener noreferrer"
       className={main? "Button-link": "Button-link Button-link-sub"}>
      {children}
    </a>
  );
}

export function SimpleButton({disabled=false, onClick=null, main=true, children}) {
  return (
      <button className={main? "Button-link": "Button-link Button-link-sub"}
        disabled={disabled}
        onClick={onClick}>
        {children}
      </button>
  );
}

export function Button({to, disabled=false, onClick=null, main=true, children}) {
  return (
      <Link to={to} className={main? "Button-link": "Button-link Button-link-sub"}>
        <button className="Button-link-Link"
          disabled={disabled}
          onClick={onClick}>
          {children}
        </button>
      </Link>
  );
}

export function ExternalButtonLarge({to, disabled=false, onClick=null, main=true, newpage=true, children}) {
  return (
    <a href={to} target={newpage? "_blank": "_self"}
       rel="noopener noreferrer"
       className={main? "Button-link Button-link-large": "Button-link Button-link-large Button-link-sub"}>
      {children}
    </a>
  );
}